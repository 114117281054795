// Bulma does not natively support putting a .navbar-item inside .navbar-item,
// which we require for the tooltips, as we need a non-disabled parent
// container which has hover events. The styles below add this functionality
// for a .navbar with .is-info coloring.

.navbar-item > .navbar-item {
  height: 100%;
}

.navbar-item:is([disabled]) {
  pointer-events: none;
  opacity: 0.65;
}

// TODO: Remove .has-navbar-item once firefox supports :has()
// This needs to be its own rule, since browserse not supporting :has()
// will ignore a rule if a single selector is unrecognized.
.navbar-item.has-navbar-item {
  padding: 0;
}

.navbar .navbar-start .navbar-item:has(.navbar-item) {
  padding: 0;
}

@media screen and (min-width: 1024px) {
  .navbar.is-info .navbar-start .navbar-item > .navbar-item {
    color: #fff;
  }

  .navbar.is-info .navbar-start .navbar-item > .navbar-item:not([disabled]),
  .navbar.is-info .navbar-start .navbar-item:hover > .navbar-item:is([disabled]),
  .navbar.is-info .navbar-start .navbar-item:hover:has([disabled]),
  .navbar.is-info .navbar-start .navbar-item:focus-within:has(.navbar-item) {
    background-color: #3e8ed0;
  }

  .navbar.is-info .navbar-item:hover > .navbar-item:not([disabled]),
  .navbar.is-info .navbar-item.is-active > .navbar-item:not([disabled]) {
    background-color: #3082c5;
  }

  .navbar-item.has-dropdown.is-active.is-hoverable:not(:hover) .navbar-dropdown {
    display: none;
  }
} 

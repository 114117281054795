.modal-card-title {
  width: 100%;
  line-height: 1.3;
}

.button.is-footer {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.button.is-left {
  border-bottom-right-radius: 0px;
}

.button.is-right {
  border-bottom-left-radius: 0px;
}

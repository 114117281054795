@charset "utf-8";

/* Bulma Switch Control */
@import "~bulma-switch-control/bulma-switch-control";
@import 'bulma-popover/bulma-popover';

@import "bulma/bulma";

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";

@import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css';

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: $info;
  border-style: dashed;
  background-color: #fafafa;
  color: $info;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  border-color: #eeeeee;
  color: #bdbdbd;
  opacity: 0.6;
}

.tag.is-circular {
  border-radius: 50%;
  border-color: $link;
}

.tag.is-circular.is-danger {
  border-radius: 50%;
  border-color: $danger;
}

.tag.is-squared {
  border-radius: 2px;
  border-color: $link;
}

// close navbar dropdown automatically
// extracted from: https://github.com/jgthms/bulma/issues/2514#issuecomment-510451361
@media screen and (min-width: 1025px) {
    .navbar-item.is-hoverable:hover .navbar-dropdown {
        display: block !important;
    }
    .navbar-item.is-hoverable:focus-within .navbar-dropdown {
        display: none;
    }
}

.has-tooltip-hidden {
    &:after, &:before {
		opacity: 0 !important;
		display: none !important;
  }
}

/* Class that manually sets the z-index of sticky objects with a modal as a child.
   Required since the model will inherit the z-index of sticky parents. */
.is-sticky.has-modal {
  z-index: 40;
}
